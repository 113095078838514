import React, { useMemo } from "react";
import { Menu } from "antd";
import {
  HomeOutlined,
  UserOutlined,
  UsergroupDeleteOutlined,
  DollarOutlined,
  TagsOutlined,
  AudioOutlined,
  CalendarOutlined,
  EnvironmentOutlined,
  ImportOutlined,
  LikeOutlined,
  ContainerOutlined,
  CopyOutlined,
  SettingOutlined,
  HistoryOutlined,
  FileOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const Sidebar: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  // const { role } = useAuth();

  // const isHavePermission = (finds: EUserRole[]) => {
  // 	return finds.includes(role);
  // }

  // const isAdmin = useMemo(() => role.includes('admin'),[role])

  const filterPermission = (items: any[]) => {
    return items
      .map((item) => {
        if (item?.permission === undefined || item.permission === true) {
          let newObject = item;
          delete item.permission;
          if (newObject.children) {
            newObject.children = filterPermission(newObject.children);
          }
          return newObject;
        } else {
          return null;
        }
      })
      .filter(Boolean);
  };

  const MENUS = [
    {
      key: "/dashboard",
      label: "Dashboard",
      icon: <HomeOutlined />,
    },
    {
      // permission: isAdmin,
      type: "group",
      label: "Transaction",
      children: [
        {
          key: "/transaction",
          label: "Transaction",
          icon: <DollarOutlined />,
        },
        {
          key: "/import",
          label: "Import Transaction",
          icon: <ImportOutlined />,
        },
      ],
    },
    {
      // permission: isAdmin,
      type: "group",
      label: "Event Management",
      children: [
        {
          key: "/event",
          label: "Event",
          icon: <CalendarOutlined />,
        },
      ],
    },
    {
      // permission: isAdmin,
      type: "group",
      label: "Master Data",
      children: [
        {
          key: "/tag",
          label: "Tag",
          icon: <TagsOutlined />,
        },
        {
          key: "/talent",
          label: "Talent",
          icon: <AudioOutlined />,
        },
        {
          key: "/venue",
          label: "Venue",
          icon: <EnvironmentOutlined />,
        },
        {
          key: "/ticket-class",
          label: "Ticket Class",
          icon: <ContainerOutlined />,
        },
        {
          key: "/event-review",
          label: "Review",
          icon: <LikeOutlined />,
        },
        {
          key: "/voucher",
          label: "Voucher",
          icon: <ContainerOutlined />,
        },
        {
          key: "/static-page",
          label: "Static Page",
          icon: <CopyOutlined />,
        },
        {
          key: "/general-setting",
          label: "General Setting",
          icon: <SettingOutlined />,
        },
        {
          key: "/payment-method",
          label: "Payment Method",
          icon: <DollarOutlined />,
        },
        {
          key: "/log-activity",
          label: "Log Activity",
          icon: <HistoryOutlined />,
        },
      ],
    },

    {
      //   permission: isAdmin,
      type: "group",
      label: "Report",
      children: [
        {
          key: "/report",
          label: "Report",
          icon: <FileOutlined />,
        },
      ],
    },

    {
      //   permission: isAdmin,
      type: "group",
      label: "User Management",
      children: [
        {
          key: "/user",
          label: "User",
          icon: <UsergroupDeleteOutlined />,
        },
        {
          key: "/role",
          label: "Role",
          icon: <UserOutlined />,
        },
      ],
    },
  ];

  const items = filterPermission(MENUS) as MenuProps["items"];

  const convertPathName = () => {
    const pathname = location.pathname.split("/");
    const res = "/" + pathname[1];
    return res;
  };

  const getSelectedParent = () => {
    const currentMenu = convertPathName();
    var parent: string[] = [];
    if (items) {
      for (const group of items) {
        if (group && "children" in group && group.children) {
          for (const submenu of group.children) {
            var current = String(submenu?.key ? submenu.key : "");
            if (submenu && "children" in submenu && submenu.children) {
              for (const item of submenu.children) {
                if (
                  item &&
                  "path" in item &&
                  (item as any).path === currentMenu &&
                  current !== undefined
                ) {
                  parent.push(current);
                }
              }
            }
          }
        }
      }
    }
    return parent;
  };

  return (
    <Menu
      mode="inline"
      defaultSelectedKeys={[convertPathName()]}
      defaultOpenKeys={getSelectedParent()}
      items={items}
      style={{ paddingBottom: 40}}
      onClick={({ key }) => {
        navigate(key);
      }}
    />
  );
};
export default Sidebar;
