import { Form, Space, Modal, Button, Select,Radio, DatePicker,Input, InputNumber, Card, Typography, Spin, Row, Col, Image, Dropdown, Table, message } from 'antd'
import React from 'react'
import moment from 'moment';
import { PlusOutlined, EditOutlined, DeleteOutlined, CopyOutlined } from '@ant-design/icons';
import HeaderSection from '../../components/HeaderSection'
import { useParams, useNavigate } from 'react-router-dom';
import { httpRequest } from '../../helpers/api';
import styled from 'styled-components';
import type { RangePickerProps } from 'antd/es/date-picker';
import { generateFormRules } from '../../helpers/formRules';
import useDetailBreadcrumbs from '../../hooks/useDetailBreadcrumbs';
import HTMLPreview from '../../components/HTMLPreview';
import { BaseResponseProps, BaseResponsePaginationProps } from '../../types/config.type'
import DetailItem from '../../components/DetailItem';
import { getErrorMessage } from '../../helpers/errorHandler';
import { formatDate, formatTime, formatYearToTime} from '../../helpers/constant';
import { 
    initialEvent, 
    initialEventVariant,
    EventProperties, 
    EDiscountType,
    EventVariantProperties,
    TicketClassProperties} from '../../types/user.type'
import type { TableProps } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

interface ILocation {
    eventId: string
}

interface ResponseProps extends BaseResponseProps<EventProperties> { }
interface ResponsePropsTicketClass extends BaseResponsePaginationProps<TicketClassProperties> { }

const EventDetail = () => {
    const navigate = useNavigate()
    const { eventId } = useParams<keyof ILocation>() as ILocation;
    const [form] = Form.useForm();
    const {setBreadcrumbDetails} = useDetailBreadcrumbs()

    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [isDataLoading, setIsDataLoading] = React.useState<boolean>(false)
	const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false)
    const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false)
	const [isModalBackOpen, setIsModalBackOpen] = React.useState<boolean>(false);
    const [event, setEvent] = React.useState<EventProperties>(initialEvent)
	const [discount, setDiscount] = React.useState<string>('')
	const [streamKey, setStreamKey] = React.useState<{[key: string]: any}>({})
	const [finalPrice, setFinalPrice] = React.useState<number>(0)
    const [tmpData, setTmpData] = React.useState<EventVariantProperties>(initialEventVariant)
    const [tmpNotif, setTmpNotif] = React.useState<{
        title: string,
        message: string
    }>({
        title: '',
        message: ''
    })
    const [variant, setVariant] = React.useState<EventVariantProperties[] | any>([])
	const [ticketData, setTicketData] = React.useState<TicketClassProperties[]>([])
    const [modalType, setModalType] = React.useState<string>('');
    const [inputStreamKeyValue, setInputStreamKeyValue] = React.useState<string>('');
	const [quota, setQuota] = React.useState<number>()
	const [action, setAction] = React.useState<string>('add')

	const currencyFormater = new Intl.NumberFormat

    const { Title } = Typography
    const { RangePicker } = DatePicker
    const { Option } = Select

    const disabledDate: RangePickerProps['disabledDate'] = current => {
      return moment(event.startAt).add(1, 'days') <= current;
    };

    const selectBefore = (
        <Select onChange={(e) => { setAction(e) }} defaultValue="add" style={{ width: 50 }}>
          <Option value="add">+</Option>
          <Option value="minus">-</Option>
        </Select>
      );

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                setIsDataLoading(true)

                const res = await httpRequest.get<ResponseProps>('/events/' + eventId)
                let resEvent = res.data.payload
                console.log(resEvent)
                // const checkIsRerun = resEvent.eventVariants.filter((value => value.isCanRerun === true))
                // if (checkIsRerun.length > 0) {
                const resRerun = await httpRequest.get<any>('/events/' + eventId + '/rerun-video')
                resEvent ={
                    ...resEvent,
                    ...resRerun.data.payload
                }
                // console.log(resRerun)
                // }
                setEvent(resEvent)

                const resTicket = await httpRequest.get<ResponsePropsTicketClass>('/ticket-classes')
                const tickets = resTicket.data.payload.results
                const filterTicket = tickets.filter(ticket => ticket.status === 'active')
                setTicketData(filterTicket)

                let variantCodes = resEvent.eventVariants.map(value => value.eventVariantCode)
                
                if(variantCodes.includes(null)){
                    setVariant(resEvent.eventVariants)
                } else {
                    const sort = resEvent.eventVariants?.sort((a, b) => a.eventVariantCode.localeCompare(b.eventVariantCode))
                    setVariant(sort)
                }

                const bcDetails = [
                    {
                      field: 'eventId',
                      value: eventId,
                      label: res.data.payload.eventTitle,
                    },
                  ];
                  setBreadcrumbDetails(bcDetails);

                const resStreamKey = await httpRequest.get(`/live-streaming/event/${eventId}/stream-key`)
                setStreamKey(resStreamKey.data.payload)

                setIsDataLoading(false)
                setIsLoading(false)
            } catch (error: any) {
                message.error(error);
                setIsLoading(false)
            }
        }
        fetchData()

        // eslint-disable-next-line
    }, [isLoading])



    const onDiscountChange = (value:any) => {
		setDiscount(value)
        form.setFieldsValue({discountValue: 0})
	}
    
    const calculateFinalPrice = () => {
		const fields = form.getFieldsValue()
		const { discountType, discountValue, price } = fields

		let countFinalPrice = 0
		if (discountType && discountValue){
			if (discountType==='NOMINAL') {
			countFinalPrice = price - discountValue
            } else {
                countFinalPrice = price - ((discountValue/100)*price)
            }
		}

		if (!discountValue) countFinalPrice = price
        if (countFinalPrice<0) countFinalPrice = 0
		form.setFieldsValue({ cmsFinalPrice: countFinalPrice })
        setFinalPrice(countFinalPrice)
	}

    const createVariant = async (value: EventVariantProperties) => {
		try {

            const saleType = modalType === 'Sale' ? 'SALE' : 'PRESALE'
            
            const eventStartDate = new Date(value.variantDate?.[0] ? value.variantDate[0] : '')
            // eventStartDate.setHours(0, 0 ,0)
            const eventEndDate = new Date(value.variantDate?.[1] ? value.variantDate[1] : '')
            // eventEndDate.setHours(23, 59 ,59)
            
            const discountStartDate = value.discountDate ? new Date(value.discountDate?.[0]) : null
            
            const discountEndDate = value.discountDate ? new Date(value.discountDate?.[1]) : null

			const formData = {
				variantName: value.variantName,
                eventId: eventId,
                ticketClassId: value.ticketClassId,
                isCanRerun: value.isCanRerun,
                eventCode: event.eventCode,
                saleType: saleType,
                eventType: value.eventType,
                startAt: eventStartDate,
                endAt: eventEndDate,
                price: value.price,
                finalPrice: finalPrice,
                discountType: value.discountType,
                discountValue: value.discountValue,
                discountStartAt: discountStartDate,
                discountEndAt: discountEndDate,
                maxStock: value.maxStock,
                currentStock: value.currentStock,
                ticketClass: value.ticketClass
			}

			const post = await httpRequest.post('/event-variants', formData)

			message.success('Success create ' + value.variantName)
			setIsLoading(true)
            // setVariant([
            //     ...post.data.payload,
            //     ...variant,])
            form.resetFields();
            setDiscount('')
            setFinalPrice(0)
            setIsModalVisible(false)
			// setIsLoadingAction(false)

		} catch (error) {
			message.error(getErrorMessage(error));
			setIsLoadingAction(false);
		}
	}

    const updateVariant = async (value: EventVariantProperties) => {
		try {
			setIsLoadingAction(true)
            if(value.currentStock > value.maxStock){
                setIsLoadingAction(false)
                return message.error('current stock tidak boleh lebih dari max stock')
            }

			const saleType = modalType === 'Sale' ? 'SALE' : 'PRESALE'

            if(quota) {
                if(action === 'add') {
                    tmpData.maxStock += quota
                    tmpData.currentStock += quota
                } else if (action === 'minus') {
                    if(
                        tmpData.maxStock - quota < 0 ||
                        tmpData.currentStock - quota < 0
                    ) {
                        setIsLoadingAction(false)
                        return message.error('Stock variant tidak boleh dibawah 0')
                    }
                    tmpData.maxStock -= quota
                    tmpData.currentStock -= quota
                }
            }

            const eventStartDate = new Date(value.variantDate?.[0] ? value.variantDate[0] : '')
            // eventStartDate.setHours(0, 0 ,0)
            const eventEndDate = new Date(value.variantDate?.[1] ? value.variantDate[1] : '')
            // eventEndDate.setHours(23, 59 ,59)

            const discountStartDate = value.discountDate ? new Date(value.discountDate?.[0]) : null
            
            const discountEndDate = value.discountDate ? new Date(value.discountDate?.[1]) : null

			const formData = {
				variantName: value.variantName,
                eventVariantId: tmpData.eventVariantId,
                eventId: value.eventId,
                ticketClassId: value.ticketClassId,
                isCanRerun: value.isCanRerun,
                saleType: saleType,
                eventCode: event.eventCode,
                eventType: value.eventType,
                startAt: eventStartDate,
                endAt: eventEndDate,
                price: value.price,
                finalPrice: finalPrice,
                discountType: value.discountType,
                discountValue: value.discountValue,
                discountStartAt: discountStartDate,
                discountEndAt: discountEndDate,
                maxStock: tmpData.maxStock,
                currentStock: tmpData.currentStock,
                ticketClass: value.ticketClass
			}

			const update = await httpRequest.patch('/event-variants/' + tmpData.eventVariantId, formData);

            let newData = []
            newData = variant.filter((item:any) =>{
                return item.eventVariantId !== tmpData.eventVariantId
            })

            // setVariant([
            //     ...newData,
            //     ...update.data.payload
            // ])
			message.success('Success update ' + value.variantName + ' data')
			setIsLoading(true)
            form.resetFields();
            setDiscount('')
            setFinalPrice(0)
            setIsModalVisible(false)
			setIsLoadingAction(false)
            setAction('add')
		} catch (error) {
			message.error(getErrorMessage(error));
			setIsLoadingAction(false)
		}
	}

    const handleDelete = async () => {
        try {
            let newData = [];
            await httpRequest.delete<ResponseProps>('/event-variants/' + tmpData.eventVariantId)
            // newData = variant.filter((item:any) =>{
            //     return item.eventVariantId !== tmpData.eventVariantId
            // })
            // setVariant(newData)
			setIsLoading(true)
            message.success('Success delete ' + tmpData.variantName)
            setIsModalVisible(false);
            setTmpData(initialEventVariant);
            setIsLoadingAction(false);
        } catch (error: any) {
            message.error(error.data.message);
            setIsModalVisible(false);
            setTmpData(initialEventVariant);
            setIsLoadingAction(false);
        }
    }

    const inputStreamKey = async () => {
	    setIsLoadingAction(true)

        // const post = await httpRequest.post('/live-streaming/', 
        // {
        //     inputStreamKey: inputStreamKeyValue
        // })

        message.success('Success input ' + inputStreamKeyValue)
        setIsModalVisible(false);
        setIsLoadingAction(false);
    }

	const showModalBack = () => {
	  setIsModalBackOpen(true);
	};
  
	const handleOkModalBack = () => {
        isModalVisible? setIsModalVisible(false) : navigate(-1)
        setIsModalBackOpen(false);
        form.resetFields()
        setFinalPrice(0)
        setDiscount('')
        setInputStreamKeyValue('')
        setTmpNotif({
            title: '',
            message: ''
        })
    };
  
	const handleCancelModalBack = () => {
	  setIsModalBackOpen(false);
	};

    const handleSubmit = async (values: EventVariantProperties) => {
		if (tmpData.eventVariantId !== '') {
			updateVariant(values)
		} else {
			createVariant(values)
		}
	}

    const handleClickEdit = () => {
        navigate('/event/'+eventId+'/edit');
    };

    const handleSendNotif = async () => {
        if(tmpNotif.message === '' || tmpNotif.title === '') {
            return message.error('Title dan message tidak boleh kosong')
        }

        setIsLoadingAction(true)
        if(modalType === 'blastNotif') {
            try {
                await httpRequest.post(`/notifications/to-all-user`, {
                    // notification: {
                    //     body: `Tiket ${event.eventTitle}\n${formatYearToTime(event.startAt)} - ${formatYearToTime(event.endAt)}\nAyo segera beli sebelum kehabisan `,
                    //     title: `Tiket ${event.eventTitle}`
                    // },
                    // data: {
                    //     type: 'EVENT',
                    //     id: event.eventId
                    // }
                    notification: {
                        body: tmpNotif.message,
                        title: tmpNotif.title
                    },
                    data: {
                        type: 'EVENT',
                        id: event.eventId
                    }
                })

                message.success('Success blast notif to all user')
                form.resetFields();
                setIsModalVisible(false)
                setModalType('')
                setTmpNotif({
                    title: '',
                    message: ''
                })
            } catch (error: any) {
                message.error(getErrorMessage(error));
            }
        } else if(modalType === 'rerunNotif') {
            try {
                await httpRequest.post(`/events/${eventId}/rerun-notification`, {
                    title: tmpNotif.title,
                    message: tmpNotif.message
                })
                
                message.success('Success send rerun notif')
                
                form.resetFields();
                setIsModalVisible(false)
                setModalType('')
                setTmpNotif({
                    title: '',
                    message: ''
                })
            } catch (error: any) {
                message.error(getErrorMessage(error));
            }
        }
        
        setIsLoadingAction(false)
    };

    const generateStreamKey = async () => {
        const generate = await httpRequest.post(`/live-streaming/event/${eventId}/generate-stream-key`)
        setStreamKey(generate.data.payload.meta.streamKey.value)
        setIsLoading(true)
    };

    const regenerateStreamKey = async () => {
        const regenerate = await httpRequest.post(`/live-streaming/event/${eventId}/generate-stream-key`, {regenerate: true})
        setStreamKey(regenerate.data.payload.meta.streamKey.value)
    };

    const str2bool = (value: string) => {
      if (value && typeof value === "string") {
        if (value.toLowerCase() === "true") return true;
        if (value.toLowerCase() === "false") return false;
      }
      return value;
    };

    const columns = [
        {
            title: 'EVENT VARIANT CODE',
            dataIndex: 'eventVariantCode',
            key: 'eventVariantCode',
            align: 'left',
            render: (eventVariantCode: string, record: EventVariantProperties) => {
                return (
                    <div>
                        {eventVariantCode ? eventVariantCode : ''}
                    </div>
                );
            },
        },
		{
			title: 'SALE TYPE',
			dataIndex: 'saleType',
			key: 'saleType',
            align: 'left',
			render: (saleType: string, record: EventVariantProperties) => {
				return (
					<div>
						{saleType}
					</div>
				);
			},
		},
		{
			title: 'TICKET CLASS',
			dataIndex: 'ticketClass',
			key: 'ticketClass',
            align: 'left',
			render: (ticketClass: string, record: EventVariantProperties) => {
				return (
					<div>
						{ticketData.filter(ticket => ticket.ticketClassId === record.ticketClassId).map(ticket => ticket.ticketClassName)}
					</div>
				);
			},
		},
        {
            title: 'VARIANT NAME',
            dataIndex: 'variantName',
            key: 'variantName',
            align: 'left',
            render: (variantName: string, record: EventVariantProperties) => {
                return (
                    <div>
                        {variantName}
                    </div>
                );
            },
        },
        {
            title: 'PRICE',
            dataIndex: 'price',
            key: 'price',
            align: 'left',
            render: (price: string, record: EventVariantProperties) => {
                return (
                    <>
                        {(() => {
							if (record.price === record.cmsFinalPrice){
								return (
									<div>Rp. {currencyFormater.format(record.cmsFinalPrice)}</div>
								)
							} else if (record.price !== record.cmsFinalPrice){
								return (
									<div> 
									<p style={{textDecoration: 'line-through', marginBottom: '0', color: '#D8D7D7'}}>
										Rp. {currencyFormater.format(record.price)}
										</p>
									Rp. {currencyFormater.format(record.cmsFinalPrice)}
									</div>
								)
							}
						})()}
                    </>
                );
            },
        },
        {
            title: 'EVENT TYPE',
            dataIndex: 'eventType',
            key: 'eventType',
            align: 'left',
            render: (eventType: string, record: EventVariantProperties) => {
                return (
                    <div>
                        {eventType}
                    </div>
                );
            },
        },
        {
            title: 'STOCK',
            dataIndex: 'maxStock',
            key: 'maxStock',
            align: 'left',
            render: (maxStock: string, record: EventVariantProperties) => {
                return (
                    <div>
                        {record.currentStock}/{maxStock}
                    </div>
                );
            },
        },
        {
            title: 'VARIANT DATE',
            dataIndex: 'date',
            key: 'date',
            align: 'left',
            render: (date: any, record: EventVariantProperties) => 
            <div>{formatYearToTime(record.startAt)} - {formatYearToTime(record.endAt)}</div>,
        },
        {
            title: '',
            key: 'action',
            render: (_: any, record: EventVariantProperties) => (
					<>
					<Button icon={<EditOutlined />} style={{marginRight: '10px'}} type="primary" 
                    onClick={() => { 
                        record.saleType === 'SALE'?  
                        setModalType('Sale') : 
                        setModalType('Presale')
                        setTmpData(record)
                        setFinalPrice(record.cmsFinalPrice)
                        form.setFieldsValue({
                            ...record,
                            variantDate: [
                                moment(record.startAt),
                                moment(record.endAt)
                            ],
                            discountDate: [
                                record.discountStartAt? moment(record.discountStartAt) : undefined,
                                record.discountEndAt? moment(record.discountEndAt) : undefined,
                            ],
                        })
                        setIsModalVisible(true)
                    }}>
                    </Button>
					<Button icon={<DeleteOutlined />} style={{backgroundColor: 'red', border: 'none'}} type="primary" 
                    onClick={() => {
                        setModalType('delete')
                        setIsModalVisible(true);
                        setTmpData(record);}}>
                    </Button>
					</>
            ),
        },
    ] as TableProps<EventVariantProperties>['columns'];

    return (
        <React.Fragment>
            <HeaderSection
                icon="back"
                title={event.eventTitle}
                subtitle="Manage event"
                rightAction={
                    <Space>
                        <Button
                        style={{background: '#264284', color: 'white'}}
                        type="primary" 
                        onClick={() => {
                            setIsModalVisible(true)
                            setModalType('rerunNotif')
                        }}
                        >
                            Blast Notif Replay
                        </Button>
                        <Button
                        style={{background: '#264284', color: 'white'}}
                        type="primary" 
                        onClick={() => {
                            setIsModalVisible(true)
                            setModalType('blastNotif')
                        }}>
                            Blast Notif All User
                        </Button>
                        <Button onClick={() => navigate('/event')}>
                            Cancel
                        </Button>
                        <Button
                        style={{background: '#264284', color: 'white'}}
                        type="primary" onClick={handleClickEdit}>
                            Edit
                        </Button>
                    </Space>
                }
            />
            <Spin spinning={isDataLoading}>
                <Card bordered={false}>
                    <Row gutter={[10,0]}>
                        <Col span={5}>
                            <Image
                            preview={false}
                            width={200}
                            src={event.coverImage ? event.coverImage : '/images/no-preview.jpg'}
                            fallback={'/images/blur-image.jpeg'}
                            style={{ objectFit: 'cover' }}
                            placeholder={true}
                            />
                        </Col>
                        <Col span={8}>
                            <DetailItem label="Event Title" value={event.eventTitle} />
                            <DetailItem label="Event Code" value={
                                event.eventCode? event.eventCode : ''} />
                            <DetailItem 
                            label="Event Description"
                            children={
                            <div className="table-link"
                            style={{textDecoration: 'none'}}
                            onClick={() => {
                              setModalType("description");
                              setIsModalVisible(true);
                            }}
                              >
                                <HTMLPreview 
                                html={
                                    event.description ? event.description.length > 400 ? event.description.split(/\s+/,75).join(' ') + '...' : event.description : 'Not Set'
                                    } />
                            </div>} />
                            <DetailItem label="Event Date" value={
                                `${formatDate(event.startAt)}, ${formatTime(event.startAt)} - ${formatTime(event.endAt)}`
                                } />
                            <DetailItem label="Event Publish Date" value={`${formatDate(event.publishedAt)}, ${formatTime(event.publishedAt)}`} />
                            <DetailItem label="Event Created By" value={event.createdBy?.name} />
                            <DetailItem label="Event Replay Max Duration" value={event.rerunMaxDuration ? `${event.rerunMaxDuration} hours` : 'Not Set' }/>
                        </Col>
                        <Col span={8}>
                            <DetailItem label="Event Talent" value={`
                                ${event.mainTalent? event.mainTalent.talentName: ''}
                                ${event.talents?
                                    event.talents.length > 0? ', ' + event.talents?.map((value:any) => value.talentName).join(', ') : '' : ''}`
                                } />
                            <DetailItem label="Venue Name" value={`
                                ${event.venue ? event.venue.venueName : 'Not Set'}`
                                } />
                            <DetailItem label="Event Tag" value={`
                                ${event.tags?
                                    event.tags.length> 0 ? event.tags.map((value:any) => value.tagName).join(', ') : 'Not Set' : 'Not Set'}`
                                } />
                            <DetailItem label="Admin Fee" value={`
                                Rp. ${event.adminFee? currencyFormater.format(event.adminFee) : '0'}`
                                } />
                            <DetailItem label="PPN" value={`
                                ${event.taxPPN? currencyFormater.format(event.taxPPN) : '0'}%`
                                } />
                            <DetailItem label="Pajak Hiburan" value={`
                                ${event.taxPajakHiburan ? currencyFormater.format(event.taxPajakHiburan) : '0'}%`
                                } />
                        </Col>
                    </Row>
                </Card>
                <Card bordered={false} size="small">
                    <Row>
						<Col>
							<Title level={4}>Replay Video Event</Title>
                            <DetailItem label="Replay Video URL" value={`
                                ${event.rerunVideoURL ? event.rerunVideoURL : 'Not Set'}`
                            } />
                            <DetailItem label="Replay Video Date" value={
                                event.rerunStartAt?
                                `${formatYearToTime(event.rerunStartAt)} - ${formatYearToTime(event.rerunEndAt)}` : 'Not Set'
                                } />
						</Col>
                    </Row>
                    <Row>
						<Col>
							<Title level={4}>Live Streaming</Title>
						</Col>
                        <Col flex="auto" style={{textAlign: 'right'}}>
                            {/* {streamKey?
                            <Button
                            style={{background: '#264284', color: 'white', marginBottom: 5}}
                            type="primary" onClick={regenerateStreamKey}>
                                Regenerate Stream Key
                            </Button> : */}
                            {streamKey?
                            <></>
                            :
                            <Button
                            style={{background: '#264284', color: 'white', marginBottom: 5}}
                            type="primary" onClick={generateStreamKey}>
                                Generate Stream Key
                            </Button>
                            }
                            {/* } */}
                            {/* <Button
                            style={{background: '#264284', color: 'white'}}
                            type="primary" onClick={() => {
                                setIsModalVisible(true)
                                setModalType('streamKey')
                            }}>
                                Input Stream Key
                            </Button> */}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <DetailItem label="Ingest Endpoint" children={
                                streamKey?
                                <div>
                                    {streamKey.ingestEndpoint}
                                    <Button 
                                    style={{background: '#264284', color: 'white', marginLeft: '10px'}}
                                    size={'small'}
                                    icon={<CopyOutlined/>}
                                    onClick={() => {
                                        setStreamKey({
                                            ...streamKey,
                                            ingestEndpointClicked: true
                                        })
                                        navigator.clipboard.writeText(streamKey.ingestEndpoint)
                                        setTimeout(() =>
                                        setStreamKey({
                                            ...streamKey,
                                            ingestEndpointClicked: false
                                        }),
                                        1000
                                        )
                                    }}>{streamKey.ingestEndpointClicked? 'Copied!' : ''}</Button>
                                </div>
                                :
                                <>Not Set</>
                            } />
                            <DetailItem label="Stream Key" children={
                                streamKey?
                                <div>
                                    {streamKey.streamKey}
                                    <Button 
                                    style={{
                                        background: '#264284', 
                                        color: 'white', 
                                        marginLeft: '10px',}}
                                    size={'small'}
                                    icon={<CopyOutlined/>}
                                    onClick={() => {
                                        setStreamKey({
                                            ...streamKey,
                                            streamKeyClicked: true
                                        })
                                        navigator.clipboard.writeText(streamKey.streamKey)
                                        setTimeout(() =>
                                        setStreamKey({
                                            ...streamKey,
                                            streamKeyClicked: false
                                        }),
                                        1000
                                        )
                                    }}>{streamKey.streamKeyClicked? 'Copied!' : ''}</Button>
                                </div>
                                :
                                <>Not Set</>
                            } />
                            <DetailItem label="Full URL" children={
                                streamKey?
                                <div>
                                    {streamKey.fullRtmsUrl}
                                    <Button 
                                    style={{background: '#264284', color: 'white', marginLeft: '10px'}}
                                    size={'small'}
                                    icon={<CopyOutlined/>}
                                    onClick={() => {
                                        setStreamKey({
                                            ...streamKey,
                                            fullRtmsUrlClicked: true
                                        })
                                        navigator.clipboard.writeText(streamKey.fullRtmsUrl)
                                        setTimeout(() =>
                                        setStreamKey({
                                            ...streamKey,
                                            fullRtmsUrlClicked: false
                                        }),
                                        1000
                                        )
                                    }}>{streamKey.fullRtmsUrlClicked? 'Copied!' : ''}</Button>
                                </div>
                                :
                                <>Not Set</>
                            } />
                        </Col>
                    </Row>
                </Card>
                <Card bordered={false} size="small">
                    <Row gutter={2}>
						<Col>
							<Title level={4}>Event Variant</Title>
						</Col>
						<Col flex="auto" style={{textAlign: 'right', marginBottom: 10}}>
								<Button icon={<PlusOutlined />} 
                                style={{background: '#264284', color: 'white', marginRight: '10px'}}
                                type="primary" 
                                onClick={() => {
                                    setModalType('Sale')
                                    setIsModalVisible(true)
                                    setTmpData(initialEventVariant)
                                }}>
									Add Sale Variant
								</Button>
								<Button icon={<PlusOutlined />} type="primary" 
                                onClick={() => {
                                    setModalType('Presale')
                                    setIsModalVisible(true)
                                    setTmpData(initialEventVariant)}}>
									Add Presale Variant
								</Button>
						</Col>
					</Row>
                    <Table
                    rowKey="eventVariantId"
                    loading={isLoading}
                    columns={columns}
                    dataSource={variant}
                    />
                </Card>
                {(() => {
                if (modalType === 'Sale' || modalType === 'Presale') {
                    return (
                        <Modal
                        width={1000}
                        style={{top: 10}}
                        open={isModalVisible}
                        onOk={() => {
                            form.submit();
                        }}
                        onCancel={() => {
                            showModalBack()
                        }}
                        okText="Save"
                        confirmLoading={isLoadingAction}
                        okButtonProps={{ type: 'primary' }}
                        >
                            <Title level={3}>{(tmpData.eventVariantId !== '' ? 'Edit ' : 'Add ') + modalType + ' variant'}</Title>
                            <Spin spinning={isLoading}>
                                    <Form
                                    form={form}
                                    name="profileForm"
                                    layout="vertical"
                                    onFinish={handleSubmit}
                                    autoComplete="off"
                                    >   
                                        <Title level={4}>Variant Information</Title>
                                        <Row gutter={20}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label="Variant Name"
                                                    name="variantName"
                                                    rules={generateFormRules('Variant Name', [
                                                        'required',
                                                    ]
                                                    )}
                                                >
                                                    <Input
                                                        onChange={(event) =>
                                                            setTmpData({ ...tmpData, variantName: event.target.value })
                                                        }
                                                        placeholder="Enter variant name here"
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                label="Variant Date"
                                                name="variantDate"
                                                rules={generateFormRules('Variant Date', [ 
                                                    'required'
                                                ]
                                                )}
                                                >
                                                    {/* <RangePicker
                                                    disabledDate={disabledDate}
                                                    style={{width: '100%'}}/> */}
                                                    <RangePicker
                                                    style={{width: '100%'}}
                                                    disabledDate={disabledDate}
                                                    format="YYYY-MM-DD HH:mm"
                                                    showTime={{ format: 'HH:mm' }}/>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={[20, 24]}>
                                            <Col span={8}>
                                                <Form.Item
                                                label="Event Variant Type"
                                                name="eventType"
                                                rules={generateFormRules('Event Variant Type', [ 
                                                    'required',
                                                ]
                                                )}
                                                >
                                                    <Radio.Group >
                                                        <CustomRadio value="ONLINE">Online</CustomRadio>
                                                        <CustomRadio value="OFFLINE">Offline</CustomRadio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item
                                                label="Ticket Class"
                                                name="ticketClassId"
                                                rules={generateFormRules('Ticket Class', [ 
                                                    'required',
                                                ]
                                                )}
                                                >
                                                    <Select
                                                        placeholder="Select Ticket Class"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                                        }
                                                    >
                                                        {ticketData.filter(ticket => ticket.status === 'active').map((ticket) => (
                                                            <Option value={ticket.ticketClassId}>{ticket.ticketClassName}</Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            {tmpData.eventVariantId !== ''?
                                            <>
                                            <Col span={4}>
                                                <Form.Item
                                                label="Stock"
                                                // name="maxStock"
                                                >
                                                    <Input
                                                        disabled
                                                        value={`${tmpData.currentStock}/${tmpData.maxStock}`}
                                                        placeholder="Enter Stock"
                                                    />
                                                </Form.Item>
                                            </Col> 
                                            <Col span={4}>
                                                <Form.Item
                                                label="Adjust Stock"
                                                name=""
                                                // rules={[{
                                                // required:true, message: 'wajib diisi, data tidak boleh kosong'
                                                // }]}
                                                >
                                                    <InputNumber
                                                    onChange={(e:any) => {
                                                        setQuota(e)
                                                    }}
                                                    controls={false}
                                                    addonBefore={selectBefore}
                                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    style={{ width: '100%' }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            </>
                                            :
                                            <Col span={8}>
                                                <Form.Item
                                                label="Stock"
                                                name="maxStock"
                                                rules={generateFormRules('Stock', [ 
                                                    'required',
                                                    'numeric'
                                                ]
                                                )}
                                                >
                                                    <Input
                                                        placeholder="Enter Stock"
                                                    />
                                                </Form.Item>
                                            </Col>
                                            }
                                        </Row>
                                        <Title level={4}>Variant Price</Title>
                                        <Row gutter={20}>
                                            <Col span={12}>
                                                <Form.Item
                                                label="Normal Price"
                                                name="price"
                                                rules={generateFormRules('Normal Price', [ 
                                                    'required',
                                                ]
                                                )}
                                                >
                                                    <InputNumber
                                                    controls={false}
                                                    prefix='Rp'
                                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    onChange={()=> calculateFinalPrice() }
                                                    style={{ width: '100%' }}
                                                    placeholder="Input Price"
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={20}>
                                            <Col span={12}>
                                                <Form.Item
                                                label="Discount Type"
                                                name="discountType"
                                                >
                                                    <Select 
                                                    style={{ width: '100%' }} 
                                                    placeholder="Select Discount Type"
                                                    onChange={
                                                        e => onDiscountChange(e)
                                                        }>
                                                    <Option value={EDiscountType.NOMINAL}>{EDiscountType.NOMINAL}</Option>
                                                    <Option value={EDiscountType.PERCENTAGE}>{EDiscountType.PERCENTAGE}</Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                            <Form.Item
                                            label="Discount Value"
                                            name="discountValue"
                                            >
                                                {(() => {
                                                    if (discount !== ''){
                                                        if (discount === 'NOMINAL'){
                                                            return (
                                                                <InputNumber
                                                                controls={false}
                                                                prefix='Rp'
                                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                onChange={ () => calculateFinalPrice()}
                                                                style={{ width: '100%' }}
                                                                />
                                                            )
                                                        } else {
                                                            return (
                                                                <InputNumber
                                                                controls={false}
                                                                onChange={() => calculateFinalPrice()}
                                                                style={{ width: '100%' }}
                                                                min={0}
                                                                max={100}
                                                                formatter={value => `${value}%`}
                                                                />
                                                            )
                                                        }
                                                    } else if (discount === '' &&
                                                        tmpData.discountType){
                                                        if (tmpData.discountType === 'NOMINAL'){
                                                            return (
                                                                <InputNumber
                                                                controls={false}
                                                                prefix='Rp'
                                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                onChange={ () => calculateFinalPrice()}
                                                                style={{ width: '100%' }}
                                                                />
                                                            )
                                                        } else {
                                                            return(
                                                                <InputNumber
                                                                controls={false}
                                                                onChange={() => calculateFinalPrice()}
                                                                style={{ width: '100%' }}
                                                                min={0}
                                                                max={100}
                                                                formatter={value => `${value}%`}
                                                                />
                                                            )
                                                        } 
                                                    } else {
                                                        return (
                                                            <InputNumber
                                                            disabled
                                                            style={{ width: '100%' }}
                                                            />
                                                        )
                                                    }
                                                })()}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={20}>
                                            <Col span={12}>
                                            <Form.Item
                                                label="Discount Date"
                                                name="discountDate"
                                            >
                                                <RangePicker
                                                style={{width: '100%'}}
                                                format="YYYY-MM-DD HH:mm"
                                                showTime={{ format: 'HH:mm' }}/>
                                            </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={20}>
                                            <Col span={12}>
                                                    <p style={{marginBottom: '0'}}>Final Price</p>
                                                    <p>Rp.{currencyFormater.format(finalPrice)}</p>
                                            </Col>
                                        </Row>
                                        <Row gutter={20}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label="Is Can Replay"
                                                    name="isCanRerun"
                                                    rules={generateFormRules('Is Can Rerun', [
                                                        'required',
                                                    ]
                                                    )}
                                                    >
                                                    <Radio.Group 
                                                        value={tmpData.isCanRerun}>
                                                        <CustomRadio value={str2bool("true")}>Yes</CustomRadio>
                                                        <CustomRadio value={str2bool("false")}>No</CustomRadio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                </Form>
                            </Spin>
                            
                        </Modal>
                    )
                } else if (modalType === 'delete') {
                    return (
                        <Modal
                        title="Delete confirmation"
                        open={isModalVisible}
                        onOk={handleDelete}
                        onCancel={() => {
                            setIsModalVisible(false);
                            setTmpData(initialEventVariant);
                        }}
                        okText="Yes"
                        confirmLoading={isLoadingAction}
                        okButtonProps={{ type: 'primary' }}
                        >
                            <p>
                                Are you sure want to delete <b>"{tmpData.variantName}"</b> ?
                            </p>
                        </Modal>
                    )
                } else if (modalType === 'rerunNotif' || modalType === 'blastNotif') {
                    return (
                        <Modal
                        title={modalType === 'rerunNotif' ?  "Replay Notif" : 'Blast Notif'}
                        open={isModalVisible}
                        onOk={handleSendNotif}
                        onCancel={() => {
                            // setIsModalVisible(false);
                            showModalBack()
                        }}
                        okText="Send"
                        confirmLoading={isLoadingAction}
                        okButtonProps={{ type: 'primary' }}
                        >
                            <Form
                            form={form}
                            layout="vertical"
                            onFinish={handleSubmit}
                            autoComplete="off"
                            >
                                <Form.Item
								label="Title"
								name="title"
								rules={[{
                                required:true, message: 'wajib diisi, data tidak boleh kosong'
                                }]}
							    >
                                    <TextArea
                                        maxLength={140}
                                        autoSize={{ minRows: 1, maxRows: 3 }}
                                        showCount
                                        onChange={(event) =>
                                            setTmpNotif({ ...tmpNotif, title: event.target.value })
                                        }
                                        placeholder="Enter notif title here"
                                    />
                                </Form.Item>
                                <Form.Item
								label="Message"
								name="message"
								rules={[{
                                required:true, message: 'wajib diisi, data tidak boleh kosong'
                                }]}
							    >
                                    <TextArea
									maxLength={190}
									showCount
									autoSize={{ minRows: 4, maxRows: 7 }}
									onChange={(event) =>
										setTmpNotif({ ...tmpNotif, 
                                        message: event.target.value 
                                        })
									}
									placeholder="Enter notif message here"
								/>
                                </Form.Item>
                            </Form>
                        </Modal>
                    )
                } else if (modalType === 'description'){
                    return (
                        <Modal
                          title="Description"
                          open={isModalVisible}
                          onCancel={() => {
                            setIsModalVisible(false);
                          }}
                          footer={null}
                        >
                          <div style={{ textAlign: "center" }}>
                          <HTMLPreview 
                                html={
                                    event.description
                                    } />
                          </div>
                        </Modal>
                    )
                } else if (modalType === 'streamKey'){
                    return (
                        <Modal
                          title="Input Stream Key"
                          open={isModalVisible}
                          onOk={() => {
                            inputStreamKey()
                            setInputStreamKeyValue('')
                          }}
                          onCancel={() => {
                              showModalBack()
                          }}
                          okText="Save"
                          confirmLoading={isLoadingAction}
                          okButtonProps={{ type: 'primary' }}
                        >
                          <Input
                                onChange={(input) =>
                                    setInputStreamKeyValue(input.target.value)
                                }
                                placeholder="Input stream key"
						    />
                        </Modal>
                    )
                }
            })()}
            </Spin>
            <Modal
                title="Cancel Confirmation"
                open={isModalBackOpen}
                onOk={handleOkModalBack}
                onCancel={handleCancelModalBack}
            >
                <p>Are you sure ? Your data won't be save.</p>
            </Modal>

            
        </React.Fragment>
    );
}

export default EventDetail

const CustomRadio = styled(Radio)`
margin-right: 1rem;
.ant-radio-checked .ant-radio-inner{
  border-color: #264284;
  box-shadow : none;
}
.ant-radio:hover .ant-radio-inner {
  background-color: white;
}
.ant-radio-checked .ant-radio-inner:after{
  background-color: #264284;
}
`;